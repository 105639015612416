import { h, resolveComponent } from 'vue'

const ListadoLiquidacion = () => import('@/app/views/liquidacion/views/ListadoLiquidacion');


const liquidacionRoutes = [
  {
    path: 'liquidacion',
    name: 'Liquidación',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'liquidacion:Listado',
        component: ListadoLiquidacion,
        meta: {
          auth: true,
          entidad: 'liquidacion'
        },
      }
    ]
  }
];

export default liquidacionRoutes;
