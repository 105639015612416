import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.medico;
const URLAdmin = apiGlobalConstants.medico;

const fetchAllAdmins = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};
const updateDatos = (adminToEdit) => {
  return httpClient.put(`${URL}/actualizar-datos-acceso/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};
const getPermisos = (adminId) => {
  return httpClient.get(`${URL}/permisos/${adminId}`).then(res => res);
};

const getEntidades = () => {
  return httpClient.get(`${URL}/entidades`).then(res => res);
}

const setPermisos = (entidades, adminId) => {
  return httpClient.put(`${URL}/permisos/${adminId}`, {entidades}).then(res => res);
};

const fetchOneAdmin = (adminId) => {
  return httpClient.get(`${URL}/${adminId}`).then(res => res.data);
};

const updateAdmin = (adminToEdit) => {
  return httpClient.put(`${URL}/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};

const createAdmin = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

const deleteAdmin = (adminId) => {
  return httpClient.delete(`${URL}/${adminId}`).then(res => res);
};

const suggestResponsables = (type, modeloId, tipoSuggest, term = "") => {
  return httpClient.get(`${URLAdmin}/${type}/suggest-${tipoSuggest}/${modeloId}?term=${term}`).then(res => res.data);
};

export {
  fetchAllAdmins,
  fetchOneAdmin,
  updateAdmin,
  deleteAdmin,
  createAdmin,
  getPermisos,
  getEntidades,
  setPermisos,
  updateDatos,
  suggestResponsables
}
