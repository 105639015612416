export default [
  {
    component: 'CNavItem',
    name: 'Expedientes',
    icon: 'cil-clipboard',
    to: '/expedientes',
    entidad: 'expedientes'
  },
  {
    component: 'CNavItem',
    name: 'Liquidación',
    icon: 'cil-money',
    to: '/liquidacion',
    entidad: 'liquidacion'
  },
  {
    component: 'CNavItem',
    name: 'Medico',
    icon: 'cil-user',
    to: '/medicos/yo',
    entidad: 'medico'
  },
  /*{
    component: 'CNavItem',
    name: 'Contactos',
    to: '/contactos',
    icon: 'cil-contact',
    entidad: 'contactos'
  },
  {
    component: 'CNavGroup',
    name: 'Matrículas',
    to: '/gestion',
    icon: 'cil-folder',
    items: [
      {
        component: 'CNavItem',
        name: 'Inscripciones',
        to: '/gestion/inscripciones',
        icon: 'cil-people',
        entidad: 'matriculas'
      },
      {
        component: 'CNavItem',
        name: 'Matrículas',
        to: '/gestion/matriculas',
        icon: 'cil-description',
        entidad: 'matriculas'
      },

      {
        component: 'CNavGroup',
        name: 'Becas',
        to: '/gestion/matriculas/becas',
        icon: 'cil-money',
        items: [
          {
            component: 'CNavItem',
            // icon: 'cil-list',
            name: 'Listado',
            to: '/gestion/matriculas/becas',
            entidad: 'becas'
          },
          {
            component: 'CNavItem',
            name: 'Informes',
            to: '/gestion/matriculas/becas/informes',
            // icon: 'cil-book',
            entidad: 'becas'
          },
        ]
      },

      // {
      //   component: 'CNavItem',
      //   name: 'Becas',
      //   to: '/gestion/matriculas/becas',
      //   icon: 'cil-money',
      //   entidad: 'becas'
      // },
      // {
      //   component: 'CNavItem',
      //   name: 'Informes',
      //   to: '/gestion/matriculas/becas/informes',
      //   icon: 'cil-book',
      //   entidad: 'becas'
      // },

      {
        component: 'CNavItem',
        name: 'Convocatorias',
        icon: 'cil-alarm',
        to: '/gestion/matriculas/convocatorias',
        entidad: 'convocatorias'
      },
      {
        component: 'CNavItem',
        name: 'Reuniones Informativas',
        icon: 'cil-alarm',
        to: '/gestion/matriculas/reuniones',
      },
      {
        component: 'CNavItem',
        name: 'Empresas',
        to: '/gestion/empresas',
        icon: 'cil-building',
        entidad: 'empresas'
      },
      {
        component: 'CNavItem',
        name: 'Profesores',
        to: '/gestion/profesores',
        icon: 'cil-education',
        entidad: 'profesores'
      },
    ]
  },

  {
    component: 'CNavItem',
    name: 'Másteres',
    to: '/masters',
    icon: 'cil-education',
    entidad: 'masters'
  },
  {
    component: 'CNavGroup',
    name: 'Contabilidad',
    to: '/facturas',
    // redirect: '/aula-virtual',
    icon: 'cil-money',
    items: [
      {
        component: 'CNavItem',
        name: 'Facturas',
        to: '/gestion/facturas',
        icon: 'cil-description',
        entidad: 'facturas'
      },
    ]
  },

  {
    component: 'CNavGroup',
    name: 'MKT',
    to: '/configuracion/marketing',
    // redirect: '/aula-virtual',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Listas Marketing',
        icon: 'cil-align-center',
        to: '/configuracion/marketing',
        entidad: 'listas'
      },
    ]
  },

  {
    component: 'CNavGroup',
    name: 'Aula virtual',
    to: '/aula-virtual',
    // redirect: '/aula-virtual',
    icon: 'cil-library-building',
    items: [
      {
        component: 'CNavItem',
        icon: 'cil-education',
        name: 'Masters',
        to: '/aula-virtual', // /masters
        entidad: 'masters'
      },
      {
        component: 'CNavItem',
        name: 'Tests A,B,C',
        to: '/aula-virtual/test-opciones',
        icon: 'cil-apps',
        entidad: 'masters'
      },
      {
        component: 'CNavItem',
        name: 'Pregunta/Respuesta',
        to: '/aula-virtual/pregunta-respuesta',
        icon: 'cil-description',
        entidad: 'masters'
      },
      {
        component: 'CNavItem',
        name: 'Encuestas satisfacción',
        to: '/aula-virtual/encuesta-satisfaccion',
        icon: 'cil-happy',
        entidad: 'masters'
      },
    ]
  },*/
]
