import { ref } from 'vue';
import { removeAccents } from '@/app/shared/utils/tools';

// import { suggestCentros } from '@/app/shared/global/services/centros-service'; 
// import { suggestMedicos } from '@/app/shared/global/services/medicos-service';
// import { suggestPacientes } from '@/app/shared/global/services/pacientes-service';
// import { suggestExpedientes } from '@/app/shared/global/services/expedientes-service';
import { suggestGlobal } from  '@/app/shared/global/services/global-service';

const DEFAULT_DEBOUNCE_DURATION_IN_MILLIS = 1_000

function debounce(callback, wait) {
  let timeoutId = null

  return (...args) => {

    if (timeoutId) window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

export function useAutocomplete(
    {
        transformData = (data) => data,
        debounceDuration = DEFAULT_DEBOUNCE_DURATION_IN_MILLIS,
    },
) {

    const isLoading = ref(false)
    const data = ref([])
    const hasFailed = ref(false)

    async function fetchSuggestions(modelo, modelId, query, suggestModel = '') {

      try {
        isLoading.value = true
        hasFailed.value = false

        const resp = await suggestGlobal(modelo, query.toLowerCase());
                 
        // suggestModel == 'centros' ? await suggestCentros(query.toLowerCase())
        //   : suggestModel == 'pacientes' ? await suggestPacientes(query.toLowerCase())
        //   : suggestModel == 'medicos' ? await suggestMedicos(query.toLowerCase())
        //   : suggestModel == 'expedientes' ? await suggestExpedientes(query.toLowerCase())
        //   : await suggestGlobal(modelo, query.toLowerCase());

        resp.data.map(r => {
          if(r.nombre) r.nombre = removeAccents(r.nombre);
          else r.name = removeAccents(r.name);

          return r;
        });


        data.value = transformData(resp.data)
      } catch (error) {
        hasFailed.value = true
      } finally {
        isLoading.value = false
      }
    }


    return {
      isLoading,
      isLoading,
      hasFailed,
      data,
      fetchSuggestions: debounce(fetchSuggestions, debounceDuration),
    }
}
