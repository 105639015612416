import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.admin;

//  i.e   api/admin/empresas/etiquetas/{id}
const fetchAllEtiquetas = (type, modeloId, page = null, perPage = null) => {
  const filter = ''; 

  if (page != null) {
    return httpClient.get(`${URL}/${type}/etiquetas/${modeloId}?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}/${type}/etiquetas/${modeloId}?${filter}`).then(res => res.data)
  }
};

const suggestEtiquetas = (type, modeloId, term = "") => {
  return httpClient.get(`${URL}/${type}/suggest-etiqueta/${modeloId}?term=${term}`).then(res => res.data);
};

const asociarEtiqueta = (type, modeloId, data) => {
  return httpClient.put(`${URL}/${type}/asociar-etiqueta/${modeloId}`, data).then(res => res.data)
};

const desasociarEtiqueta = (type, modeloId, data) => {
  return httpClient.put(`${URL}/${type}/desasociar-etiqueta/${modeloId}`, data).then(res => res.data)
};

export {
  fetchAllEtiquetas,
  suggestEtiquetas,
  asociarEtiqueta,
  desasociarEtiqueta,
}
