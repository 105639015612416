<template>
    <div class="animated fadeIn">
      <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">
  
        <CModalHeader class="bg-primary text-light">
          <CModalTitle>Nueva Lesión</CModalTitle>
        </CModalHeader>
  
        <spinner-ttt :show="loading"></spinner-ttt>
        <CModalBody>
          <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
            <CRow>
                <CCol class="mb-3" sm="4">
                    <CFormLabel class="required">Título</CFormLabel>
                    <Field
                    as="CFormInput"
                    type="text"
                    id="titulo" name="titulo"
                    placeholder="Introduce un título"
                    v-model="lesion.titulo"
                    :rules="'required'"
                    :class="{ 'is-invalid': formError(errors, 'titulo')}"
                    v-bind:invalid-feedback="errors.titulo"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'titulo')">{{ errors.titulo }}</small>
                </CCol>
                <CCol sm="4">
                    <label class="mb-2 required" for="estados">Categoría</label>
                    <CFormSelect
                        class="mb-3"
                        id="categoria" name="categoria"
                        v-model="lesion.categoria_id"
                    >
                        <option v-for="(item) in categorias" :value="item.value">
                            {{ item.label }}
                        </option>
                    </CFormSelect>
                </CCol>
                <CCol class="mb-3" sm="4">
                            <CFormLabel :class="'required'">Coste</CFormLabel>
                            <Field
                            as="CFormInput"
                            type="number"
                            id="coste" name="coste"
                            :rules="'required'"
                            placeholder="Introduce un coste"
                            v-model="lesion.coste"
                            :class="{ 'is-invalid': formError(errors, 'coste'), 'text-end': true}"
                            v-bind:invalid-feedback="errors.coste"
                            />
                            <small class="text-danger ml-2" v-if="formError(errors, 'coste')">{{ errors.coste }}</small>
                        </CCol>
                </CRow>
                <CRow>
                <CCol sm="12">
                    <CFormTextarea
                    id="descripcion" name="descripcion"
                    label="Descripción"
                    placeholder="Introduce una descripción"
                    v-model="lesion.descripcion"
                    rows="3"
                    />
                </CCol>
            </CRow>
            <CRow>
              <CCol sm="12" class="mt-2">
                <CFormLabel >Imágenes</CFormLabel>
                <DragDropInput
                  size="8388608"
                  multiple
                  :maxFiles="4"
                  @saveFile="saveFile($event)"
                  @erroFile="disabled = true"
                />
              </CCol>
            </CRow>
  
          </Form>
        </CModalBody>
  
         <CModalFooter>
          <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
          <CButton class="text-light" @click="crearLesion" color="primary">
            <!-- <CIcon name="cil-check-circle" />  -->
            Crear
          </CButton>
         </CModalFooter>
  
      </CModal>
    </div>
    <toast ref="toast"></toast>
  </template>
  
  <script>  
  import { LESION_FIELDS } from '@/config';
  import { formError, setStoreData } from '@/app/shared/utils/tools';
  
  export default {
    name: 'NuevaLesionModal',
    props: {
      show: { type: Boolean, default: false, required: true },
    },
    computed: {
      showModal() {
        return this.show;
      },
    },
    data() {
      return {
        listadoTipos: [],
        lesion: {...LESION_FIELDS},
        loading: false,
        categorias: [
            {
                label: 'Nevus',
                value:1
            },
            {
                label: 'Milium Sebaceo',
                value:2
            },
            {
                label: 'Léntigo',
                value:3
            },
            {
                label: 'Araña Vascular',
                value:4
            },
            {
                label: 'Pápulas Fibrosas',
                value:5
            },
            {
                label: 'Acrocordones',
                value:6
            },
            {
                label: 'Punto Rubí',
                value:7
            },
            {
                label: 'Otros',
                value:8
            }
        ]
      }
    },
    methods: {
      crearLesion() {
        this.$refs.form.validate().then(v => {
          if(!v.valid) return
  
          //this.loading = true;
          this.$emit('crearLesion', this.lesion);
        })
      },
  
      formError(errors, value) {
        return formError(errors, value);
      },
  
      cerrarModal() {
        this.loading = false;
        this.lesion = {...LESION_FIELDS};
        this.$emit('cancelled',false);
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  