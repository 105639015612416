import { ADMIN_API,MEDICO_API, PUBLICO_API } from "@/config";

export const apiGlobalConstants = {
  admin: ADMIN_API,
  medico: `${MEDICO_API}/medico`,
  administrador: `${ADMIN_API}/administradores`,
  contacto: `${ADMIN_API}/contactos`,
  empresa: `${ADMIN_API}/empresas`,
  persona: `${ADMIN_API}/personas`,
  tipologia: `${PUBLICO_API}/tipologias`,
  sede: `${ADMIN_API}/sedes`,
  comentario: `${ADMIN_API}/comentarios`,
  producto: `${ADMIN_API}/productos`,
  curso: `${ADMIN_API}/cursos`,
  fichero: `${MEDICO_API}/ficheros`,
  master: `${ADMIN_API}/masters`,
  profesor: `${ADMIN_API}/profesores`,
  direccion: `${ADMIN_API}/direcciones`,
  publico : `${ADMIN_API}/publico`,
  cuota: `${ADMIN_API}/cuotas`,
  alumno: `${ADMIN_API}/alumnos`,
  matricula: `${ADMIN_API}/matriculas`,
  beca: `${ADMIN_API}/becas`,
  convocatoria: `${ADMIN_API}/convocatorias`,
  log: `${ADMIN_API}/logs`,
  grupo: `${ADMIN_API}/grupos`,
  gasto: `${ADMIN_API}/gastos`,
  venta: `${ADMIN_API}/ventas`,
  informes: `${ADMIN_API}/informes`,
  movimiento: `${ADMIN_API}/movimientos`,
  lista: `${ADMIN_API}/listas`,
  expedientes: `${MEDICO_API}/expedientes`,
  liquidaciones: `${MEDICO_API}/liquidaciones`,
  lesiones: `${MEDICO_API}/lesiones`,
  pacientes: `${MEDICO_API}/pacientes`,
  centros: `${MEDICO_API}/centros`,
  factura: `${ADMIN_API}/facturas`
}
