<template>
  <CCard class="mb-2 mt-2">
    <spinner-ttt :show="loadingChild"></spinner-ttt>
    <CCardHeader class="pointer" @click="etiquetasCollapsed = !etiquetasCollapsed">
      <slot name="header">
        <span class="title-card-list text-dark">
          <CIcon name="cil-tags" size="lg" class="me-2" />
          <strong>Etiquetas ({{ items.length }})</strong>
        </span>
      </slot>

      <div class="card-header-actions float-end">
        <CIcon v-if="etiquetasCollapsed" name="cil-chevron-bottom" />
        <CIcon v-else name="cil-chevron-top" />
      </div>
    </CCardHeader>
    <spinner-ttt :show="loadingList"></spinner-ttt>
    <CCollapse :visible="!etiquetasCollapsed" :duration="400">
      <CCardBody class="overflow-auto">
        <!--  style="max-height: 500px" -->
        <span ref="commentContainer" id="commentContainer"></span>


        <!-- ////////////////////////////////// -->
        <div class="search-wrapper">
          <div class="search-content">
            <!-- <h1 class="search-headline">Selecciona las etiquetas</h1> -->

            <div v-if="isLoading" class="loading-container">
              <CSpinner size="sm" class="loader" color="secondary" />
            </div>
            <input type="text" class="search-input" placeholder="escribe..." v-model="searchQuery" @input="onInput" />

            <p v-if="showAddNew" class="search-new-item" @click="addNuevaEtiqueta()"> Añadir etiqueta</p>

            <ul class="search-list">
              <!-- <strong v-if="isLoading" class="search-loader">
                Cargando...
              </strong> -->

              <strong v-if="hasFailed || errorApi" class="search-loader">
               Ha ocurrido un error, inténtalo en unos minutos
              </strong>

              <li v-for="(item, index) in data" :key="index">
                <!-- v-c-tooltip="{ content: 'Pincha para asociar la etiqueta', placement: 'top' }" -->
                <button class="search-item"
                  @click="addEtiqueta(item)">
                  {{ item.etiqueta }}
                </button>
              </li>
            </ul>

            <!-- <div v-if="selectedItem" class="search-card">
              <p>
                <strong>{{ selectedItem }}</strong>
              </p>
            </div> -->

          </div>
        </div>


        <!-- ////////////////////////////////// -->


        <div class="mt-4">
          <span v-for="item in items" :key="item.id" class="m-1">
            <CBadge color="info" @click="desAsociarEtiqueta(item)">
              <span class="d-flex align-items-center justify-content-between">
                <span style="margin-right: 5px">{{ item.etiqueta }}</span>
                <CIcon class="ml-2 text-dark pointer" name="cil-x-circle" />
              </span>
            </CBadge>
          </span>
        </div>


        <span ref="scrollToMe" id="scrollToMe"></span>
      </CCardBody>
      <CCardFooter class="white">
        <div class="d-flex align-items-center justify-content-between">

          <!-- Elige entra las etiquetas ya creadas o añade una nueva. -->
          Elige entre las etiquetas disponibles, pulsa para añadir la selecionada o añade una nueva

        </div>
      </CCardFooter>

    </CCollapse>
  </CCard>

  <toast ref="toast"></toast>
</template>

<script>
import { ref, computed } from 'vue';
import { useAutocomplete } from '../../../use/useAutocomplete/useAutocomplete';

import {
  desasociarEtiqueta,
  asociarEtiqueta,
  // suggestEtiquetas,
  fetchAllEtiquetas
} from '@/app/shared/global/services/etiqueta-service';

export default {
  name: 'CardListadoEtiquetas',
  components: {},
  props: {
    loading: Boolean,
    loaded: Boolean,
    etiquetas: { type: Array, default() { return [] }, required: true },
    modeloTipo: { type: String, required: true },
    modeloId: { type: Number, required: true },
    modelCampos: { type: Object, default() { return {} }, required: true },
    resetData: { type: Boolean, default: false }
  },
  emits: ['create', 'getData', 'update:etiquetas', 'update:loading', 'updateLoading'],
  setup(props, context) {

    const { fetchSuggestions, data, isLoading, hasFailed } = useAutocomplete(
      {
        transformData: (data) => data,
        debounceDuration: 500,
      },
    )

    const searchQuery = ref('')
    const selectedItem = ref(null)
    const canShowSuggestions = ref(false)
    const errorApi = ref(false)

    function onInput() {

      if (searchQuery.value?.length > 2) {
        canShowSuggestions.value = true
        fetchSuggestions(props.modeloTipo, props.modeloId, searchQuery.value, 'etiqueta')
      } else {
        data.value = []
        selectedItem.value = null
      }
    }

    // asociar etiqueta descartado
    function onItemClick(item) {
      selectedItem.value = item
      canShowSuggestions.value = false
      searchQuery.value = item.etiqueta
    }

    function addNuevaEtiqueta() {
  
      asociarNuevaEtiqueta({etiqueta: searchQuery.value});
    }

    async function addEtiqueta(item) {
      selectedItem.value = item
      searchQuery.value = item.etiqueta
      canShowSuggestions.value = false

      asociarNuevaEtiqueta(item);
    }

    const asociarNuevaEtiqueta = async (item) => {
      // const errorMessage = `Error al asociar la etqueta`;
      try {
        errorApi.value = false;
        const resp = await asociarEtiqueta(props.modeloTipo, props.modeloId, item);
        if (resp.status != 'success') throw resp.message;
        
        // reset
        data.value = []
        selectedItem.value = null
        searchQuery.value = ''

        // update view
        context.emit('getData', false);

      } catch (e) {
        console.log(e);
        errorApi.value = true;
        // this.$refs.toast.showToast(errorMessage, null, "error", "5000");
      }

    }

    const checkEtiquetaNueva = () => {
      return props.etiquetas.filter( item => item.etiqueta == searchQuery.value).length === 0
    }

    return {
      // onItemClick,
      onInput,
      isLoading,
      hasFailed,
      searchQuery,
      data,
      selectedItem,
      errorApi,
      addEtiqueta,
      addNuevaEtiqueta,
      showAddNew: computed(() => data.value.length == 0 && searchQuery.value?.length > 2 && !isLoading.value && checkEtiquetaNueva())
    }
  },
  data() {
    return {
      element: 'etiquetas',
      etiquetasCollapsed: true,
      loadingChild: false,
    }
  },
  computed: {
    items: {
      get: function () { return [...this.etiquetas] },
      set: function (newValue) { this.$emit("update:etiquetas", newValue) },
    },
    loadingList: {
      get: function () { return this.loading },
      set: function (newValue) { this.$emit('update:loading', newValue) },
    },
    etiquetaExistente() {
      this.items.includes()
    }
  },
  methods: {
    async desAsociarEtiqueta(etiqueta) {
      const errorMessage = `Error eliminando la ${this.element}`;

      try {
        const resp = await desasociarEtiqueta(this.modeloTipo, this.modeloId, { etiqueta_id: etiqueta.id });

        if (resp.status != 'success') {
          return
        }

        this.$refs.toast.showToast('Etiqueta eliminada', null, "success", "5000");
        this.items.splice(this.items.indexOf(this.items.filter(i => i.id == etiqueta.id)[0]), 1);
        this.$emit('getData', true);

      } catch (e) {
        console.log(e);
        this.$refs.toast.showToast(errorMessage, null, "error", "5000");
      }
    },

    async getModelEtiquetas() {

      const errorMessage = `Error obteniendo las ${this.element}`

      fetchAllEtiquetas(this.modeloTipo, this.modeloId).then(resp => {
        if (resp.status == 'success') {
          this.$nextTick(() => {
            this.items = resp.data?.length > 0 ? [...resp.data] : [];
          });

        } else {
          this.$refs.toast.showToast(errorMessage, null, "error", "5000");
        }
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast(errorMessage, null, "error", "5000");
      }).finally(() => {

      })
    },

  }
}
</script>

<style lang="scss" scoped>

.search {
  &-wrapper {
    // font-family: 'Courier New', Courier, monospace;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  &-content {
    // width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-headline {
    text-align: center;
  }

  &-input {
    padding: 10px;
    border: 1px solid lightgray;

    &:focus {
      border-color: turquoise !important;
    }
  }

  &-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &-loader {
    margin-top: 10px;
  }

  &-error {
    margin-top: 10px;
  }

  &-item {
    border: 1px solid lightgray;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;

    &:hover{
      background-color: #e6f9fd;
      border: 1px solid #ccf2fa;
    }
  }

  &-new-item {
    cursor: pointer;
    color: #0a6d81;
    margin-top: 0.2rem;
    text-align: right;
    &:hover{
      color: #003d75;
    }
  }

  &-card {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid lightgray;
    word-wrap: break-word;
  }
}



</style>
